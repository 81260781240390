import Boots from "../../../photos/boots.png"
const NetworkGameView = () => {

    return (
        <>
            <div class ="project_view">
                <h1 class="project_title title">
                    Network Project Coming Soon....
                </h1>
                <img src={Boots} class="headshot"></img>
            </div>
        
        </>
    );
}
export default NetworkGameView