
const Name = () => {
  return (
    <>
        <div class="main_title main_item">
          <h1>Andrew Chapman
          </h1>
          <text >Jr. Software Engineer</text>
        </div>
    </>
  );
};
export default Name;