
const About = () => {
  return (
    <section className='section'>
      <h2 class= "about_title">About Me</h2>
      <ul class="about_text">
        <li>
        Hello, I'm Andrew Chapman, but you can just call me Drew. I'm originally from Visalia, 
        California, and I've always loved solving technical problems. My fascination with 
        figuring out how things work started way back when I was a kid playing with Legos. 
        I'd take them apart and put them back together just to understand what made them tick.
        </li>
        <li>
        In high school, I was really into sports. I was the captain of my water polo team, and helped lead my team to
        second place in the valley championship. I also raced mountain bikes competitively 
        and consistently ranked in the top ten in the state. I still keep these hobbies alive today, 
        balancing my love mountain biking and swiming with my other interests. Soon after highschool I started working 
        at a bike shop where I continued my passion for solving technical problems.
        </li>
        <li>
        My journey into computer science began at the College of the Sequoias. Like many, the 
        COVID-19 pandemic made me rethink the way the world works. It hit me that technology is at the heart of 
        everything around us, and I wanted to be part of it. That's when I discovered coding,
        and it just clicked. I found that I had a knack for breaking down complex problems and 
        finding solutions. I quickly declared myself a computer science major and the rest is 
        history.
        </li>
        <li>
        After completing all of the computer science class I could take at community college I transfered
        to California State University of Fresno. While at Fresno State I worked as a classroom aid at
        a school for troubled youth, where I helped kids from tough neighborhoods see the value of
        education. In the summer of 2023, I had an exciting gig as a guide for backpacking trips at
        a summer camp. I helped plan and create a program to keep kids safe and ensure they had a 
        blast on their backpacking adventures.
        </li>
        <li>
        With my mix of technical skills, problem-solving ability, and determination, I truly believe 
        I can be a valuable addition to any software development team. I'm eager to keep growing in 
        this field and continue making a positive impact with my work.
        </li>
      </ul>
    </section>
  );
};
export default About;
